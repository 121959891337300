import {FormsService} from '../../services/checkout/FormsService';
import {DataExtensionsService, SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {CheckoutService, MinimumOrderErrorData} from '../../services/checkout/CheckoutService';
import {ApiAddress, CheckoutStoreProps, PlaceOrderUrlParams, StepId} from '../../../types/checkoutApp.types';
import {CheckoutSettingsService} from '../../services/checkout/CheckoutSettingsService';
import {NavigationService} from '../../services/checkout/NavigationService';
import {BIService} from '../../services/checkout/BIService';
import {BiMobilePosition, StepName} from '../../utils/bi.util';
import {AddressModel} from '../../models/checkout/Address.model';
import {isSubdivisionValidForCountry} from '../../utils/localeDataset.util';
import {
  PolicyButtonLocation,
  PolicyType,
} from '../../../common/components/PolicyButtonWithDialog/PolicyButtonWithDialog';
import {ADD_NEW_ADDRESS_ID, FedopsInteractions} from '../../../components/Checkout/constants';
import {ErrorType, GeneralCheckoutErrorCode} from '../../utils/errors';
import {StepsManagerService} from '../../services/checkout/StepsManagerService';
import {shouldShowGiftCardSection} from '../../utils/shouldShowGiftCardSection';
import {shouldShowCouponSection} from '../../utils/shouldShowCouponSection';
import {isPickupFlow, isShippingFlow} from '../../utils/checkoutFlow.utils';
import {WithResultObserver} from '@wix/function-result-observation/dist/types/worker/withResultObserver.worker';
import {AddPaymentInfo} from '../../utils/analytics.utils';
import {
  mapAddressModelToApiAddress,
  mapContactModelToContactDetails,
  shouldHideBillingDetailsSection,
} from '../../utils/billingDetails.utils';
import {MemberService} from '../../services/checkout/MemberService';
import {PaymentError} from '../../../types/payment.types';
import {ApiAddressFragment, CustomFieldFragment, FullAddressContactDetailsFragment} from '../../../gql/graphql';
import {ViolationModel} from '../../models/checkout/Violation.model';
import {calcTopThreeViolations, hasErrorViolations} from '../../utils/violations.utils';
import {checkoutOpenCloseYourOrderSummaryInMobileParams} from '@wix/bi-logger-ecom-platform-data/v2/types';
import {SPECS} from '../../../common/constants';
import {ViolationOtherTargetName} from '../../models/checkout/ViolationOtherTargetModel.model';
import {SuggestedFix} from '../../models/checkout/ViolationLineItemTargetModel.model';

export type CheckoutStoreConfig = {
  flowAPI: ControllerFlowAPI;
  siteStore: SiteStore;
  checkoutService: CheckoutService;
  formsService: FormsService;
  updateComponent: () => void;
  checkoutSettingsService: CheckoutSettingsService;
  navigationService: NavigationService;
  biService: BIService;
  stepsManagerService: StepsManagerService;
  dataExtensionsService: DataExtensionsService;
  memberService: MemberService;
  observe: WithResultObserver;
};

export class CheckoutStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly siteStore: SiteStore;
  private readonly checkoutService: CheckoutService;
  private readonly formsService: FormsService;
  private readonly navigationService: NavigationService;
  private readonly stepsManagerService: StepsManagerService;
  private readonly dataExtensionsService: DataExtensionsService;
  private readonly memberService: MemberService;
  private readonly updateComponent: () => void;
  private readonly checkoutSettingsService: CheckoutSettingsService;
  private readonly biService: BIService;
  private readonly isFastFlow!: boolean;
  private readonly observe: WithResultObserver;
  private isCouponLoading: boolean = false;
  private isGiftCardLoading: boolean = false;

  constructor({
    flowAPI,
    siteStore,
    checkoutService,
    formsService,
    updateComponent,
    checkoutSettingsService,
    navigationService,
    biService,
    stepsManagerService,
    observe,
    memberService,
    dataExtensionsService,
  }: CheckoutStoreConfig) {
    this.flowAPI = flowAPI;
    this.siteStore = siteStore;
    this.checkoutService = checkoutService;
    this.formsService = formsService;
    this.updateComponent = updateComponent;
    this.checkoutSettingsService = checkoutSettingsService;
    this.navigationService = navigationService;
    this.biService = biService;
    this.stepsManagerService = stepsManagerService;
    this.dataExtensionsService = dataExtensionsService;
    this.isFastFlow = navigationService.isFastFlow;
    this.observe = observe;
    this.memberService = memberService;
  }

  private readonly applyCoupon = async (couponCode: string, mobilePosition?: string): Promise<void> => {
    this.flowAPI.fedops.interactionStarted(FedopsInteractions.ApplyCouponInteraction);
    this.flowAPI.panoramaClient?.transaction(FedopsInteractions.ApplyCouponInteraction).start();
    this.biService.clickApplyCoupon({couponCode, checkout: this.checkoutService.checkout, mobilePosition});
    this.checkoutService.applyCouponError = undefined;
    if (this.flowAPI.experiments.enabled(SPECS.ShouldRedesignCouponsSection)) {
      this.isCouponLoading = true;
    }
    this.updateComponent();
    await this.checkoutService.applyCoupon(couponCode, mobilePosition);
    if (this.flowAPI.experiments.enabled(SPECS.ShouldRedesignCouponsSection)) {
      this.isCouponLoading = false;
    }
    this.updateComponent();
    this.flowAPI.fedops.interactionEnded(FedopsInteractions.ApplyCouponInteraction);
    this.flowAPI.panoramaClient?.transaction(FedopsInteractions.ApplyCouponInteraction).finish();
  };

  private readonly removeCoupon = async (mobilePosition?: string): Promise<void> => {
    if (this.flowAPI.experiments.enabled(SPECS.ShouldRedesignCouponsSection)) {
      this.isCouponLoading = true;
      this.updateComponent();
    }
    await this.checkoutService.removeCoupon(mobilePosition);
    if (this.flowAPI.experiments.enabled(SPECS.ShouldRedesignCouponsSection)) {
      this.isCouponLoading = false;
    }
    this.updateComponent();
  };

  private readonly onAddCouponSectionOpen = (mobilePosition?: string): void => {
    this.biService.clickToAddCoupon({checkout: this.checkoutService.checkout, mobilePosition});
  };

  private readonly onAddGiftCardSectionOpen = (mobilePosition?: string): void => {
    this.biService.giftCardCheckoutClickOnCheckbox(this.checkoutService.checkout, mobilePosition);
  };

  private readonly onInvalidDetailsFormSubmit = (): void => {
    this.biService.sendCheckoutErrorBIEvent(this.checkoutService.checkout, {
      stage: this.stepsManagerService.getActiveStep().stepId,
      errorMessage: 'Missing or Invalid Fields',
    });
  };

  private readonly applyGiftCard = async (giftCardCode: string, mobilePosition?: string): Promise<void> => {
    this.flowAPI.fedops.interactionStarted(FedopsInteractions.ApplyGiftCardInteraction);
    this.flowAPI.panoramaClient?.transaction(FedopsInteractions.ApplyGiftCardInteraction).start();
    this.biService.giftCardCheckoutClickApply(this.checkoutService.checkout, mobilePosition);
    this.checkoutService.applyGiftCardError = undefined;
    if (this.flowAPI.experiments.enabled(SPECS.ShouldRedesignCouponsSection)) {
      this.isGiftCardLoading = true;
    }
    this.updateComponent();
    await this.checkoutService.applyGiftCard(giftCardCode, mobilePosition);
    if (this.flowAPI.experiments.enabled(SPECS.ShouldRedesignCouponsSection)) {
      this.isGiftCardLoading = false;
    }
    this.updateComponent();
    this.flowAPI.fedops.interactionEnded(FedopsInteractions.ApplyGiftCardInteraction);
    this.flowAPI.panoramaClient?.transaction(FedopsInteractions.ApplyGiftCardInteraction).finish();
  };

  private readonly removeGiftCard = async (mobilePosition?: string): Promise<void> => {
    if (this.flowAPI.experiments.enabled(SPECS.ShouldRedesignCouponsSection)) {
      this.isGiftCardLoading = true;
      this.updateComponent();
    }
    await this.checkoutService.removeGiftCard(mobilePosition);
    if (this.flowAPI.experiments.enabled(SPECS.ShouldRedesignCouponsSection)) {
      this.isGiftCardLoading = false;
    }
    this.updateComponent();
  };

  private readonly getCustomFieldData = (customFieldValue: string): CustomFieldFragment => {
    const {customField: customFieldSettings} = this.checkoutSettingsService.checkoutSettings;
    return {
      title: customFieldSettings?.untranslatedTitle,
      value: customFieldValue,
      translatedTitle: customFieldSettings?.title,
    };
  };

  private readonly setFastFlowFormFields = async (
    extendedFieldsValue: any,
    customFieldValue?: string
  ): Promise<void> => {
    const customField = customFieldValue ? this.getCustomFieldData(customFieldValue) : undefined;
    await this.checkoutService.setFastFlowFormFields({extendedFields: extendedFieldsValue, customField});
    await this.formsService.setExtendedFieldsFormValid(
      this.checkoutService.checkout,
      this.navigationService.isFastFlow
    );
    this.updateComponent();
  };

  private readonly onDeliveryMethodContinue = () => {
    this.biService.deliveryMethodSet(this.checkoutService.checkout, this.checkoutService.originalShippingTitle);
    this.stepsManagerService.goToNextStep({checkout: this.checkoutService.checkout});
    this.updateComponent();
  };

  private readonly submitCustomerDetails = async ({
    contactDetails,
    email,
    customFieldValue,
    extendedFieldsValue,
    shippingAddress,
    addressesServiceId,
    setAsDefault,
    shouldSubscribe,
  }: {
    contactDetails?: FullAddressContactDetailsFragment;
    email?: string;
    customFieldValue?: string;
    shippingAddress?: ApiAddressFragment;
    addressesServiceId?: string;
    extendedFieldsValue?: any;
    setAsDefault?: boolean;
    shouldSubscribe?: boolean;
    // eslint-disable-next-line sonarjs/cognitive-complexity
  }): Promise<void> => {
    const customField = customFieldValue ? this.getCustomFieldData(customFieldValue) : undefined;
    const shouldUpdateBillingWithShippingInfo = !this.checkoutService.checkout.payNowTotalAfterGiftCard.amount;
    this.formsService.setShouldSubscribe(shouldSubscribe);

    this.biService.shippingAddressSet(this.checkoutService.checkout, shouldUpdateBillingWithShippingInfo, true);

    this.flowAPI.fedops.interactionStarted(FedopsInteractions.SubmitCustomerDetailsInteraction);
    this.flowAPI.panoramaClient?.transaction(FedopsInteractions.SubmitCustomerDetailsInteraction).start();

    if (addressesServiceId && shippingAddress && contactDetails) {
      if (
        addressesServiceId === ADD_NEW_ADDRESS_ID ||
        this.memberService.hasInvalidAddressServiceId(addressesServiceId)
      ) {
        addressesServiceId = await this.memberService.createMemberInfo(contactDetails, shippingAddress, setAsDefault!);
        this.biService.memberAddressCreated(
          this.checkoutService.checkout,
          addressesServiceId,
          this.stepsManagerService.getActiveStep().stepId,
          setAsDefault!
        );
      } else {
        const addressInfo = this.memberService.getAddressByAddressesServiceId(addressesServiceId);
        contactDetails.vatId = addressInfo?.contact.vatId;
        await this.memberService.updateMemberInfo(contactDetails, shippingAddress, addressesServiceId, setAsDefault!);
        this.biService.memberAddressUpdated(
          this.checkoutService.checkout,
          addressesServiceId,
          this.stepsManagerService.getActiveStep().stepId,
          setAsDefault!
        );
      }
    }

    if (addressesServiceId && (!shippingAddress || /* istanbul ignore next */ !contactDetails)) {
      const addressInfo = this.memberService.getAddressByAddressesServiceId(addressesServiceId);
      contactDetails = mapContactModelToContactDetails(addressInfo!.contact);
      shippingAddress = mapAddressModelToApiAddress(addressInfo!.address);
    }

    await this.checkoutService.setShippingInfo({
      contactDetails: contactDetails!,
      email: this.memberService.isMember() ? this.memberService.currentUserEmail : email,
      customField,
      extendedFields:
        Object.keys((extendedFieldsValue as object) ?? /* istanbul ignore next */ {}).length === 0
          ? undefined
          : extendedFieldsValue,
      shippingAddress,
      addressesServiceId,
    });

    this.flowAPI.fedops.interactionEnded(FedopsInteractions.SubmitCustomerDetailsInteraction);
    this.flowAPI.panoramaClient?.transaction(FedopsInteractions.SubmitCustomerDetailsInteraction).finish();

    if (!this.checkoutService.updateCheckoutError) {
      const previouslyCompletedFirstStep = this.stepsManagerService.completedFirstStep;
      this.stepsManagerService.goToNextStep({checkout: this.checkoutService.checkout});
      if (
        this.stepsManagerService.getActiveStep().stepId === StepId.deliveryMethod &&
        this.shouldSkipDeliveryMethodStep() &&
        !previouslyCompletedFirstStep
      ) {
        this.stepsManagerService.goToNextStep({checkout: this.checkoutService.checkout});
      }
    }

    this.updateComponent();
  };

  private readonly shouldSkipDeliveryMethodStep = () => {
    return (
      this.checkoutService.checkout.selectedShippingOption &&
      (this.isPickupFlow() || this.navigationService.isPreselectedFlow)
    );
  };

  private readonly shouldShowAddShippingAddressSection = () => {
    return this.isPickupFlow();
  };

  private readonly AddShippingAddressToPreselectedFlow = (): void => {
    this.checkoutService.setIsPickupButShippingFlow(true);
  };

  private readonly setZipCode = async (zipCodeValue: string): Promise<void> => {
    const {billingInfo, shippingDestination} = this.checkoutService.checkout;

    if (this.checkoutService.checkout.hasShippableItems) {
      const address: ApiAddress = {
        ...shippingDestination?.address,
        postalCode: zipCodeValue,
      };

      this.biService.shippingAddressSet(this.checkoutService.checkout, true, true);
      await this.checkoutService.setSingleAddress(address);

      if (this.checkoutService.checkout.shippingOptions.length === 0) {
        this.biService.sendCantShipToDestinationBIEvent(this.checkoutService.checkout);
      }
    } else {
      const billingAddress: ApiAddress = {
        ...billingInfo?.address,
        postalCode: zipCodeValue,
      };
      await this.checkoutService.setBillingAddress(billingAddress);
    }

    this.updateComponent();
  };

  private readonly setSubdivision = async (subdivisionValue?: string): Promise<void> => {
    const {shippingDestination, billingInfo} = this.checkoutService.checkout;

    if (this.checkoutService.checkout.hasShippableItems) {
      const address: ApiAddress = {
        ...shippingDestination?.address,
        subdivision: subdivisionValue,
      };

      this.biService.shippingAddressSet(this.checkoutService.checkout, true, true);
      await this.checkoutService.setSingleAddress(address);

      if (this.checkoutService.checkout.shippingOptions.length === 0) {
        this.biService.sendCantShipToDestinationBIEvent(this.checkoutService.checkout);
      }
    } else {
      const billingAddress: ApiAddressFragment = {
        ...billingInfo?.address,
        subdivision: subdivisionValue,
      };

      await this.checkoutService.setBillingAddress(billingAddress);
    }

    this.updateComponent();
  };

  private readonly removeLineItem = async (lineItemId: string): Promise<void> => {
    await this.checkoutService.removeLineItem(lineItemId);
    this.updateComponent();
  };

  private async handleContactSubscription(shouldSubscribe: boolean): Promise<void> {
    if (
      (!this.checkoutSettingsService.checkoutSettings.isSubscriptionEnabled &&
        !this.formsService.doesFormHaveSubscription) ||
      !this.checkoutService.checkout.buyerInfo.email
    ) {
      return;
    }
    if (shouldSubscribe) {
      await this.checkoutService.subscribe();
      const isSubscriptionCheckedByDefault = this.formsService.doesFormHaveSubscription
        ? Boolean(this.formsService.isSubscriptionCheckboxCheckedByDefault)
        : this.checkoutSettingsService.checkoutSettings.isSubscriptionCheckedByDefault;

      this.biService.clickPlaceOrderWithSubscription(this.checkoutService.checkout, isSubscriptionCheckedByDefault);
    }
  }

  public readonly shouldShowGiftCardSection = (): boolean =>
    shouldShowGiftCardSection({
      checkoutSettings: this.checkoutSettingsService.checkoutSettings,
      checkout: this.checkoutService.checkout,
    });

  private readonly clickPlaceOrderButton = async (
    shouldSubscribe: boolean,
    paymentDetailsId?: string
  ): Promise<
    {orderId?: string | null; paymentResponseToken?: string | null; paymentError?: PaymentError} | undefined
  > => {
    const shouldSubscribeFlag =
      this.formsService.doesFormHaveSubscription && !this.navigationService.isFastFlow
        ? Boolean(this.formsService.shouldSubscribe)
        : shouldSubscribe;

    const shouldUpdateBillingWithShippingInfo = !this.checkoutService.checkout.payNowTotalAfterGiftCard.amount;
    this.flowAPI.fedops.interactionStarted(FedopsInteractions.PlaceOrderInteraction);
    this.flowAPI.panoramaClient?.transaction(FedopsInteractions.PlaceOrderInteraction).start();
    this.biService.clickPlaceOrder(this.checkoutService.checkout, this.stepsManagerService.stepsList);
    void this.handleContactSubscription(shouldSubscribeFlag);

    if (shouldUpdateBillingWithShippingInfo) {
      const shippingAddress = this.checkoutService.checkout.shippingDestination?.address;
      const contactDetails = mapContactModelToContactDetails(
        this.checkoutService.checkout.shippingDestination?.contact
      );
      const address = shippingAddress?.country ? mapAddressModelToApiAddress(shippingAddress) : undefined;
      const addressesServiceId = this.checkoutService.checkout.shippingDestination?.addressesServiceId;

      await this.checkoutService.setBillingDetails({
        contactDetails,
        address,
        addressesServiceId,
      });
    }
    const data = await this.checkoutService.createOrderAndCharge(
      paymentDetailsId,
      await this.getPlaceOrderUrlParams(this.checkoutService.checkout.hasSubscriptionItems)
    );
    this.updateComponent();
    this.flowAPI.fedops.interactionEnded(FedopsInteractions.PlaceOrderInteraction);
    this.flowAPI.panoramaClient?.transaction(FedopsInteractions.PlaceOrderInteraction).finish();
    await this.handlePlaceOrderErrors();

    return data;
  };

  private readonly setPaymentAndBillingDetails = async ({
    contactDetails,
    address,
    addressesServiceId,
    setBillingSameAsShipping,
    activePaymentId,
  }: {
    contactDetails?: FullAddressContactDetailsFragment;
    address?: ApiAddressFragment;
    addressesServiceId?: string;
    setBillingSameAsShipping: boolean;
    activePaymentId?: string;
  }): Promise<boolean> => {
    let hasUpdateCheckoutSucceded = true;
    const totalToPayBeforeUpdateCheckout = this.checkoutService.checkout.payNowTotalAfterGiftCard.amount;
    this.biService.selectPaymentCategory(this.checkoutService.checkout, {paymentMethod: activePaymentId});
    const addressInfo = this.memberService.getAddressByAddressesServiceId(addressesServiceId);
    if (
      setBillingSameAsShipping ||
      shouldHideBillingDetailsSection(this.checkoutService.checkout, this.flowAPI.experiments, activePaymentId)
    ) {
      contactDetails = mapContactModelToContactDetails(this.checkoutService.checkout.shippingDestination?.contact);
      address = mapAddressModelToApiAddress(this.checkoutService.checkout.shippingDestination?.address);
      addressesServiceId = this.checkoutService.checkout.shippingDestination?.addressesServiceId;
    } else if (addressesServiceId && addressInfo && (!address || /* istanbul ignore next */ !contactDetails)) {
      contactDetails = mapContactModelToContactDetails(addressInfo.contact);
      address = mapAddressModelToApiAddress(addressInfo.address);
    }

    this.flowAPI.fedops.interactionStarted(FedopsInteractions.SetBillingAndPaymentInteraction);
    this.flowAPI.panoramaClient?.transaction(FedopsInteractions.SetBillingAndPaymentInteraction).start();
    await this.checkoutService.setBillingDetails({
      contactDetails: contactDetails!,
      address,
      addressesServiceId,
    });
    this.flowAPI.fedops.interactionEnded(FedopsInteractions.SetBillingAndPaymentInteraction);
    this.flowAPI.panoramaClient?.transaction(FedopsInteractions.SetBillingAndPaymentInteraction).finish();
    const totalToPayAfterUpdateCheckout = this.checkoutService.checkout.payNowTotalAfterGiftCard.amount;

    if (
      totalToPayBeforeUpdateCheckout !== totalToPayAfterUpdateCheckout &&
      !this.checkoutService.checkout.hasOutOfStockLineItems
    ) {
      this.checkoutService.setUpdateCheckoutError({
        code: GeneralCheckoutErrorCode.CHECKOUT_CHANGED,
        type: ErrorType.GENERAL,
      });
      this.biService.sendCheckoutTotalsHaveBeenUpdatedPopUpShown(this.checkoutService.checkout);
      hasUpdateCheckoutSucceded = false;
    }

    if (!this.checkoutService.updateCheckoutError) {
      this.flowAPI.controllerConfig.wixCodeApi.window.trackEvent(
        ...AddPaymentInfo({activePaymentMethod: activePaymentId})
      );

      this.biService.paymentDetailsCompleted(this.checkoutService.checkout, {paymentMethod: activePaymentId});
    } else {
      hasUpdateCheckoutSucceded = false;
    }

    this.updateComponent();
    return hasUpdateCheckoutSucceded;
  };

  private readonly setBillingDetails = async ({
    contactDetails,
    address,
    addressesServiceId,
    setAsDefault,
  }: {
    contactDetails?: FullAddressContactDetailsFragment;
    address?: ApiAddressFragment;
    addressesServiceId?: string;
    setAsDefault?: boolean;
  }): Promise<void> => {
    /* istanbul ignore else */
    if (addressesServiceId && address && contactDetails) {
      if (
        addressesServiceId === ADD_NEW_ADDRESS_ID ||
        this.memberService.hasInvalidAddressServiceId(addressesServiceId)
      ) {
        addressesServiceId = await this.memberService.createMemberInfo(contactDetails, address, setAsDefault!);
        this.biService.memberAddressCreated(
          this.checkoutService.checkout,
          addressesServiceId,
          this.stepsManagerService.getActiveStep().stepId,
          setAsDefault!
        );
      } else {
        await this.memberService.updateMemberInfo(contactDetails, address, addressesServiceId, setAsDefault!);
        this.biService.memberAddressUpdated(
          this.checkoutService.checkout,
          addressesServiceId,
          this.stepsManagerService.getActiveStep().stepId,
          setAsDefault!
        );
      }
    }

    await this.checkoutService.setBillingDetails({
      contactDetails: contactDetails!,
      address,
      addressesServiceId,
    });

    this.updateComponent();
  };

  private async getPlaceOrderUrlParams(isSubscription: boolean): Promise<PlaceOrderUrlParams> {
    const thankYouPageUrlTemplate = await this.navigationService.getThankYouPageUrlForPlaceOrder(isSubscription);
    return {
      errorUrl: this.navigationService.getCheckoutErrorUrl(),
      cancelUrl: this.navigationService.getCheckoutCancelUrl(),
      successUrl: thankYouPageUrlTemplate,
      pendingUrl: thankYouPageUrlTemplate,
    };
  }

  private readonly handlePlaceOrderErrors = async () => {
    if (this.checkoutService.placeOrderError) {
      await this.checkoutService.fetchCheckout();
      this.updateComponent();
      this.biService.sendFailedToCompleteOrderBIEvent(this.checkoutService.checkout, {
        stage: this.isFastFlow ? StepName.PLACE_ORDER_FAST_FLOW : this.stepsManagerService.getActiveStep().stepId,
        field: this.checkoutService.placeOrderError.code,
        errorMessage: JSON.stringify(this.checkoutService.placeOrderError),
      });
    }
  };

  private readonly isPlaceOrderButtonDisabled = (): boolean => {
    return (
      (this.navigationService.isFastFlow &&
        (this.shouldShowSubdivisionSelector() ||
          this.shouldRequireZipCode() ||
          !this.formsService.isExtendedFieldsFormValid ||
          (this.checkoutService.checkout.hasShippableItems &&
            !this.checkoutService.checkout.selectedShippingOption))) ||
      (this.shouldShowViolations() && hasErrorViolations(this.checkoutService.checkout.violations))
    );
  };

  public readonly getAddress = (): AddressModel | undefined => {
    return this.checkoutService.checkout.hasShippableItems
      ? this.checkoutService.checkout.shippingDestination?.address
      : this.checkoutService.checkout.billingInfo?.address;
  };

  private readonly shouldRequireZipCode = (): boolean => {
    const currentAddress = this.getAddress();
    const postalCode = currentAddress?.postalCode;

    const isShippable = this.checkoutService.checkout.hasShippableItems;

    const canShipToDestination = !!this.checkoutService.checkout.selectedShippingOption;
    const isShippingZipCodeError =
      !postalCode &&
      (!canShipToDestination || !isShippable) &&
      this.checkoutService.checkout.errors.hasShippingZipCodeError;
    const isTaxZipCodeError = this.checkoutService.checkout.errors.hasTaxZipCodeError;

    return !this.shouldShowSubdivisionSelector() && (isShippingZipCodeError || isTaxZipCodeError);
  };

  private readonly shouldShowSubdivisionSelector = (): boolean => {
    const isSubdivisionValid = isSubdivisionValidForCountry(this.getAddress());
    const isShippable = this.checkoutService.checkout.hasShippableItems;

    if (!isShippable) {
      return !isSubdivisionValid;
    }

    const canShipToDestination = !!this.checkoutService.checkout.selectedShippingOption;
    const isShippingSubdivisionError =
      !isSubdivisionValid && !canShipToDestination && this.checkoutService.checkout.errors.hasShippingSubdivisionError;
    const isTaxSubdivisionError = this.checkoutService.checkout.errors.hasTaxSubdivisionError;

    return isShippingSubdivisionError || isTaxSubdivisionError;
  };

  private readonly shouldShowViolations = (): boolean => {
    return this.checkoutService.checkout.violations.length > 0;
  };

  private readonly onPolicyClicked = (linkLocation: PolicyButtonLocation, policyType: PolicyType) => {
    this.biService.checkoutClickOnCheckoutPolicies(this.checkoutService.checkout, linkLocation, policyType);
  };

  private readonly onMobileFoldableSummaryToggle = (
    mobilePosition: BiMobilePosition,
    mobileSummaryToggleParams: Partial<checkoutOpenCloseYourOrderSummaryInMobileParams>
  ) => {
    this.biService.sendOnMobileFoldableSummaryToggle(
      this.checkoutService.checkout,
      mobilePosition,
      mobileSummaryToggleParams
    );
  };

  private readonly getMinOrderRemainingAmount = () => {
    return Number((this.checkoutService.placeOrderError?.data as MinimumOrderErrorData).remaining.amount);
  };

  private readonly getMinOrderAmount = () => {
    return Number((this.checkoutService.placeOrderError?.data as MinimumOrderErrorData).minimumOrderAmount.amount);
  };

  public readonly shouldShowCouponSection = (): boolean => {
    return shouldShowCouponSection(this.checkoutSettingsService.showCouponSP, this.checkoutService.checkout);
  };

  public readonly sendCheckoutPageLoadBIEvent = (): void => {
    this.biService.checkoutPageLoad(this.checkoutService.checkout, this.stepsManagerService.stepsList);
  };

  private readonly checkoutPageFinishLoading = (): void => {
    this.flowAPI.fedops.interactionEnded(FedopsInteractions.CheckoutPageLoaded);
    this.biService.checkoutPageFinishLoading(this.checkoutService.checkout, this.stepsManagerService.stepsList);
  };

  private readonly reportViolationRemoveButtonClick = ({
    message,
    lineItemId,
    suggestedFix,
  }: {
    message?: string;
    lineItemId?: string;
    suggestedFix?: SuggestedFix;
  }): void => {
    this.biService.checkoutClickToRemoveItemDueToCheckoutRestrictionsSrc130Evid74({
      checkout: this.checkoutService.checkout,
      message,
      lineItemId,
      suggestedFix,
    });
  };

  private readonly onHideBillingDetails = (): void => {
    this.biService.checkoutBillingAddressAsDeliverAddress(this.checkoutService.checkout);
  };

  private readonly onErrorDialogOpened = (): void => {
    if (
      this.checkoutService.placeOrderError &&
      this.checkoutService.placeOrderError?.type === ErrorType.MINIMUM_ORDER_AMOUNT
    ) {
      const stage = this.isFastFlow ? StepName.PLACE_ORDER_FAST_FLOW : this.stepsManagerService.getActiveStep().stepId;
      this.biService.checkoutMinimumOrderModalIsShownInCheckout(
        this.checkoutService.checkout,
        this.getMinOrderRemainingAmount(),
        this.getMinOrderAmount(),
        stage
      );
    }
    if (this.checkoutService.checkout.errors.noItemsError) {
      this.biService.sendCheckoutErrorBIEvent(this.checkoutService.checkout, {
        stage: this.isFastFlow ? StepName.ONLOAD_OR_UPDATE_FAST_FLOW : this.stepsManagerService.getActiveStep().stepId,
        field: this.checkoutService.checkout.errors.noItemsError.code,
        errorMessage: JSON.stringify(this.checkoutService.checkout.errors.noItemsError),
      });
    }
  };

  private readonly onErrorDialogClosed = (): void => {
    const stage = this.isFastFlow ? StepName.PLACE_ORDER_FAST_FLOW : this.stepsManagerService.getActiveStep().stepId;
    if (this.checkoutService.placeOrderError) {
      if (this.checkoutService.placeOrderError?.type === ErrorType.MINIMUM_ORDER_AMOUNT) {
        this.biService.checkoutMinimumOrderClickOnGotItInErrorModalInCheckout(
          this.checkoutService.checkout,
          this.getMinOrderRemainingAmount(),
          this.getMinOrderAmount(),
          stage
        );
      }
      this.checkoutService.clearPlaceOrderError();
      this.updateComponent();
    }

    if (this.checkoutService.checkout.errors.noItemsError) {
      this.biService.clickOnContinueShopping(this.checkoutService.checkout);
      this.navigationService.navigateToContinueShopping();
    }

    if (this.checkoutService.updateCheckoutError) {
      if (this.checkoutService.updateCheckoutError?.code === GeneralCheckoutErrorCode.CHECKOUT_CHANGED) {
        this.biService.sendCheckoutClickOnCtaInTotalsHaveBeenUpdatedPopup(this.checkoutService.checkout);
      }
      this.checkoutService.clearUpdateCheckoutError();
      this.updateComponent();
    }
  };

  private readonly isPickupFlow = (): boolean => {
    return isPickupFlow({navigationService: this.navigationService, checkoutService: this.checkoutService});
  };

  private readonly isShippingFlow = (): boolean => {
    return isShippingFlow({navigationService: this.navigationService, checkoutService: this.checkoutService});
  };

  private readonly onLogoutDialogToggled = (isOpen: boolean) => {
    isOpen
      ? this.biService.checkoutClickLogoutInCheckout(this.checkoutService.checkout)
      : this.biService.checkoutLogoutDialogClickReturnToCheckout(this.checkoutService.checkout);
  };

  private readonly mainViolationsToDisplay = (): ViolationModel[] => {
    if (this.siteStore.experiments.enabled(SPECS.SupportDeliveryViolationsOnCheckout)) {
      return calcTopThreeViolations(this.checkoutService.checkout.violations, [
        ViolationOtherTargetName.default,
        ViolationOtherTargetName.delivery,
      ]);
    }
    return calcTopThreeViolations(this.checkoutService.checkout.violations, [ViolationOtherTargetName.default]);
  };

  private readonly defaultViolationsToDisplay = (): ViolationModel[] => {
    return calcTopThreeViolations(this.checkoutService.checkout.violations, [ViolationOtherTargetName.default]);
  };

  private readonly deliveryViolationsToDisplay = (): ViolationModel[] => {
    return calcTopThreeViolations(this.checkoutService.checkout.violations, [ViolationOtherTargetName.delivery]);
  };

  /* istanbul ignore next */
  private readonly setPlaceOrderPaymentError = (paymentError: PaymentError, errorCode: string) => {
    /* istanbul ignore next */
    this.checkoutService.setPlaceOrderPaymentError(paymentError, errorCode);
    /* istanbul ignore next */
    this.updateComponent();
  };

  private readonly setIsSubscriptionCheckboxCheckedByDefault = (isSubscriptionCheckboxCheckedByDefault: boolean) => {
    this.formsService.setIsSubscriptionCheckboxCheckedByDefault(isSubscriptionCheckboxCheckedByDefault);
  };

  public toProps(): CheckoutStoreProps {
    return {
      checkout: this.checkoutService.checkout,
      extendedFieldsView: this.dataExtensionsService.getDataExtendedFieldsView(
        this.checkoutService.checkout.extendedFields
          ? {
              namespaces: this.checkoutService.checkout.extendedFields.namespaces,
            }
          : null
      ),
      placeOrderError: this.checkoutService.placeOrderError,
      setPlaceOrderPaymentError: this.setPlaceOrderPaymentError,
      setIsSubscriptionCheckboxCheckedByDefault: this.setIsSubscriptionCheckboxCheckedByDefault,
      applyCouponError: this.checkoutService.applyCouponError,
      applyGiftCardError: this.checkoutService.applyGiftCardError,
      updateCheckoutError: this.checkoutService.updateCheckoutError,
      hasPartialOutOfStockError: this.checkoutService.hasPartialOutOfStockError,

      isPlaceOrderButtonDisabled: this.isPlaceOrderButtonDisabled(),
      submitCustomerDetails: this.submitCustomerDetails,
      onDeliveryMethodContinue: this.onDeliveryMethodContinue,
      setZipCode: this.setZipCode,
      setSubdivision: this.setSubdivision,
      shouldRequireZipCode: this.shouldRequireZipCode(),
      shouldShowSubdivisionSelector: this.shouldShowSubdivisionSelector(),

      isFastFlow: this.isFastFlow,
      isPreselectedFlow: this.navigationService.isPreselectedFlow,
      isPickupFlow: this.isPickupFlow(),
      isShippingFlow: this.isShippingFlow(),
      isPickupButShippingFlow: this.checkoutService.isPickupButShippingFlow,

      applyCoupon: this.applyCoupon,
      removeCoupon: this.removeCoupon,
      isCouponLoading: this.isCouponLoading,
      applyGiftCard: this.applyGiftCard,
      removeGiftCard: this.removeGiftCard,
      isGiftCardLoading: this.isGiftCardLoading,
      setFastFlowFormFields: this.setFastFlowFormFields,
      removeLineItem: this.removeLineItem,
      onAddCouponSectionOpen: this.onAddCouponSectionOpen,
      onAddGiftCardSectionOpen: this.onAddGiftCardSectionOpen,
      onErrorDialogOpened: this.onErrorDialogOpened,
      onErrorDialogClosed: this.onErrorDialogClosed,
      onPolicyClicked: this.onPolicyClicked,
      onMobileFoldableSummaryToggle: this.onMobileFoldableSummaryToggle,
      onHideBillingDetails: this.onHideBillingDetails,
      shouldShowGiftCardSection: this.shouldShowGiftCardSection(),
      shouldShowCouponSection: this.shouldShowCouponSection(),
      onLogoutDialogToggled: this.onLogoutDialogToggled,
      shouldShowViolations: this.shouldShowViolations(),
      mainViolationsToDisplay: this.mainViolationsToDisplay(),
      defaultViolationsToDisplay: this.defaultViolationsToDisplay(),
      deliveryViolationsToDisplay: this.deliveryViolationsToDisplay(),
      shouldShowAddShippingAddressSection: this.shouldShowAddShippingAddressSection(),
      AddShippingAddressToPreselectedFlow: this.AddShippingAddressToPreselectedFlow,
      onInvalidDetailsFormSubmit: this.onInvalidDetailsFormSubmit,
      ...this.observe({
        clickPlaceOrderButton: this.clickPlaceOrderButton,
        setPaymentAndBillingDetails: this.setPaymentAndBillingDetails,
        setBillingDetails: this.setBillingDetails,
      }),
      fixCustomPolicyLongText: this.siteStore.experiments.enabled(SPECS.FixCustomPolicyLongText),
      checkoutPageFinishLoading: this.checkoutPageFinishLoading,
      reportViolationRemoveButtonClick: this.reportViolationRemoveButtonClick,
    };
  }
}
